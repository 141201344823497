import React, { useEffect, useState } from 'react'
import {
    Colors,
    getActiveListingCount,
    Logger,
    selectActiveListingCount,
    selectListingsLimit,
    selectSubscription,
    selectSubscriptionTier,
    setCredentials,
    useAppDispatch,
    useAppSelector,
    UserRuntimeContextProvider
} from '@foxtail-dev/user-clients'
import { Box, Divider, LinearProgress } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { ListingManagementTitle } from '../../components/account/ListingManagementTitle'
import { CurrentSubscriptionCard } from '../../components/account/CurrentSubscriptionCard'
import { SubscriptionCardMobile } from '../../components/account/SubscriptionCardMobile'
import { FoxButton } from '../../components/common/FoxButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '../../components/icons/ChevronLeftIcon'
import { UserSubscriptionTier } from '@foxtail-dev/datacontracts/dist/lib/schemas/subscriptions/UserSubscription'
import { FlexGrow } from '../../components/common/FlexGrow'
import { generateStripeCancelUrl, generateStripeReturnUrl, generateStripeSuccessUrl } from '../../utils/generateStripeCallbackUrls'
import { UpdateUserInfo } from '@foxtail-dev/datacontracts/dist/lib/schemas/actions/concreteSchemas/user'
import * as Intercom from '@intercom/messenger-js-sdk'
import { MustManageSubscriptionOnAppleDevicesModal } from '../../modals/account/MustManageSubscriptionOnAppleDevicesModal'
import RewardfulClient from '../../lib/clients/RewardfulClient'

export const SubscriptionMobileScreen: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const subscription = useAppSelector(selectSubscription)
    const currentActiveListingCount = useAppSelector(selectActiveListingCount)
    const listingsLimit = useAppSelector(selectListingsLimit)
    const subscriptionTier = useAppSelector(selectSubscriptionTier)
    const [selectedSubscription, setSelectedSubscription] = useState<UserSubscriptionTier>(UserSubscriptionTier.Enum.basic)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const urlAccessToken = queryParams.get('accessToken')
    const [isMustManageSubscriptionOnAppleDeviceModalOpen, setIsMustManageSubscriptionOnAppleDeviceModalOpen] = useState<boolean>(false)
    const [isIntercomOpen, setIsIntercomOpen] = useState(false)

    useEffect(() => {
        const handleShow = () => setIsIntercomOpen(true)
        const handleHide = () => setIsIntercomOpen(false)

        if (window.Intercom) {
            window.Intercom('onShow', handleShow)
            window.Intercom('onHide', handleHide)
        }

        return () => {
            if (window.Intercom) {
                window.Intercom('offShow', handleShow)
                window.Intercom('offHide', handleHide)
            }
        }
    }, [])

    const closeMustManageSubscriptionOnAppleDeviceModal = () => {
        setIsMustManageSubscriptionOnAppleDeviceModalOpen(false)
    }

    useEffect(() => {
        if (urlAccessToken) {
            dispatch(setCredentials({ credentials: { accessToken: urlAccessToken } }))
        }
    }, [urlAccessToken])

    useEffect(() => {
        Logger.I().log({
            level: 'info',
            message: 'User viewed subscription screen',
            payload: {
                kind: 'UserAction',
                entry: {
                    subscription,
                    currentActiveListingCount
                }
            }
        })

        dispatch(getActiveListingCount())

        const activeListingCountsInterval = setInterval(() => {
            dispatch(getActiveListingCount())
        }, 5000)

        return () => {
            clearInterval(activeListingCountsInterval)
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const onPurchasePlan = async (subscriptionKind: UserSubscriptionTier) => {
        try {
            const context = await UserRuntimeContextProvider.getContext()
            await context.userActionClient.executeActionRequest<UpdateUserInfo>({
                kind: 'user/updateUserInfo',
                params: {
                    preferredSubscriptionProvider: 'stripe'
                }
            })

            if (subscriptionKind === 'enterprise') {
                if (isIntercomOpen === false) {
                    Intercom.showNewMessage("I'd like to subscribe to an enterprise plan")
                } else {
                    Intercom.hide()
                }
                return
            }

            const referralId = await RewardfulClient.getReferralId()
            const { sessionUrl } = await context.userApiClient.stripe.createCheckoutSession({
                subscriptionTier: subscriptionKind,
                cancelUrl: generateStripeCancelUrl({ isMobile: true }),
                successUrl: generateStripeSuccessUrl({ isMobile: true }),
                referralId
            })
            window.location.href = sessionUrl
        } catch (error) {
            Logger.I().log({
                level: 'error',
                message: 'Error creating checkout session',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        error
                    }
                }
            })
        }
    }

    const onManageSubscription = async () => {
        try {
            if (subscription?.kind === 'active' && subscription.details.kind === 'stripe') {
                const context = await UserRuntimeContextProvider.getContext()
                const { sessionUrl } = await context.userApiClient.stripe.createBillingPortalSession({
                    returnUrl: generateStripeReturnUrl({ isMobile: true })
                })

                window.location.href = sessionUrl
            } else if (subscription?.kind === 'active' && subscription.details.kind === 'apple') {
                setIsMustManageSubscriptionOnAppleDeviceModalOpen(true)
            } else {
                Intercom.showNewMessage('How do I manage my subscription?')
            }
        } catch (error) {
            Logger.I().log({
                level: 'error',
                message: 'Error creating billing portal',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        error
                    }
                }
            })
        }
    }

    const onBack = () => {
        navigate('/app/home')
    }

    return (
        <Box sx={[styles.container, { height: 'calc(var(--vh, 1vh) * 100)' }]}>
            {subscriptionTier === 'none' ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100vh' }}>
                    <Box
                        sx={{
                            marginBottom: '32px',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}>
                        <Box
                            sx={{
                                flexShrink: 0,
                                flexDirection: 'row',
                                display: 'flex',
                                paddingTop: '56px',

                                paddingBottom: '29px',
                                position: 'sticky',
                                top: 0,
                                backgroundColor: 'white', // Ensure it doesn't overlap
                                zIndex: 1
                            }}>
                            <Box sx={{ marginLeft: '20px', flex: 1 }} onClick={onBack}>
                                <ChevronLeftIcon color={Colors.light.tertiary} height={20} width={12} />
                            </Box>
                            <FoxTypography sx={[styles.textAllPlans, { flex: 1 }]} variant='subtitle1'>
                                Select plan
                            </FoxTypography>
                            <Box sx={{ flex: 1, marginRight: '20px' }} />
                        </Box>

                        <Box
                            sx={{
                                flexDirection: 'column',
                                display: 'flex',
                                flex: 1,
                                marginBottom: '38px',
                                marginLeft: '14px',
                                marginRight: '13px',
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }}>
                            <SubscriptionCardMobile
                                sx={styles.subscriptionCard}
                                subscriptionKind='starter'
                                currentlySelectedTier={selectedSubscription}
                                setCurrentlySelectedTier={setSelectedSubscription}
                            />
                            <SubscriptionCardMobile
                                sx={styles.subscriptionCard}
                                subscriptionKind='basic'
                                currentlySelectedTier={selectedSubscription}
                                setCurrentlySelectedTier={setSelectedSubscription}
                            />
                            <SubscriptionCardMobile
                                sx={styles.subscriptionCard}
                                subscriptionKind='premium'
                                currentlySelectedTier={selectedSubscription}
                                setCurrentlySelectedTier={setSelectedSubscription}
                            />
                            <SubscriptionCardMobile
                                subscriptionKind='pro'
                                currentlySelectedTier={selectedSubscription}
                                setCurrentlySelectedTier={setSelectedSubscription}
                            />
                            <SubscriptionCardMobile
                                subscriptionKind='enterprise'
                                currentlySelectedTier={selectedSubscription}
                                setCurrentlySelectedTier={setSelectedSubscription}
                            />
                        </Box>

                        <Box
                            sx={{
                                flexShrink: 0,
                                marginLeft: '12px',
                                marginRight: '12px',
                                position: 'sticky',
                                bottom: 0,
                                backgroundColor: 'white', // Ensure it doesn't overlap
                                zIndex: 1
                            }}>
                            <Divider />
                            <FoxButton
                                primary
                                text={`Choose`}
                                onFoxClick={{
                                    kind: 'button',
                                    onClick: async () => {
                                        await onPurchasePlan(selectedSubscription)
                                    },
                                    preventDoubleClick: true
                                }}
                                sx={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    marginBottom: '60px',
                                    marginTop: '31px'
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Box sx={styles.containerMain}>
                        <Box sx={{ flexDirection: 'row', display: 'flex', marginBottom: '29px', marginTop: '56px' }}>
                            <Box sx={{ marginLeft: '20px', flex: 1 }} onClick={onBack}>
                                <ChevronLeftIcon color={Colors.light.tertiary} height={20} width={12} />
                            </Box>
                            <FoxTypography sx={[styles.textAllPlans, { flex: 1 }]} variant='subtitle1'>
                                Current subscription
                            </FoxTypography>
                            <Box sx={{ flex: 1, marginRight: '20px' }} />
                        </Box>
                        <Box sx={{ marginLeft: '14px', marginRight: '13px' }}>
                            <Divider sx={styles.divider} />
                            <CurrentSubscriptionCard />

                            <ListingManagementTitle />
                            <Divider sx={styles.divider} />

                            {/* TODO make this linear progress bar the fox gradient */}
                            <LinearProgress
                                sx={styles.linearProgress}
                                value={subscriptionTier === 'enterprise' ? 100 : (currentActiveListingCount / listingsLimit) * 100}
                                variant='determinate'
                            />
                        </Box>
                    </Box>
                    <FlexGrow />
                    <Divider />
                    <Box sx={{ marginLeft: '12px', marginRight: '12px' }}>
                        <FoxButton
                            primary
                            text={`Manage subscription`}
                            onFoxClick={{ kind: 'button', onClick: onManageSubscription, preventDoubleClick: true }}
                            sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: '60px', marginTop: '31px' }}
                        />
                    </Box>
                    <MustManageSubscriptionOnAppleDevicesModal
                        onClose={closeMustManageSubscriptionOnAppleDeviceModal}
                        open={isMustManageSubscriptionOnAppleDeviceModalOpen}
                    />
                </Box>
            )}
        </Box>
    )
}

const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    containerMain: {
        marginBottom: '32px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    containerSubscriptionCards: {
        flexDirection: 'column',
        display: 'flex',
        marginBottom: '38px',
        marginLeft: '14px',
        marginRight: '13px',
        overflow: 'hidden'
    },
    subscriptionCard: {
        marginRight: '20px'
    },
    divider: {
        color: '#F2ECF3',
        marginBottom: '35px'
    },
    linearProgress: {
        height: '13px'
    },
    textAllPlans: {
        marginTop: '24px',
        marginBottom: '24px',
        fontSize: '16px',
        lineHeight: '24px'
    }
}
