import { isValidPrice, normalizePrice } from '@foxtail-dev/user-clients'
import { FoxTextField } from './FoxTextField'
import { useMemo, useState } from 'react'
import { Box, BoxProps, TextFieldProps, TypographyVariant } from '@mui/material'
import { AutoAwesome } from '@mui/icons-material'
import { FlexGrow } from '../common/FlexGrow'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { delayMs } from '@foxtail-dev/datacontracts'

type PriceFieldProps = {
    name: string
    label?: string
    labelVariant?: TypographyVariant
    disabled?: boolean
    logOnBlur?: boolean
    sx?: BoxProps['sx']
    inputStyle?: TextFieldProps['sx']
    initialValue?: string
}

export const PriceField = ({ name, label = 'Price', disabled, logOnBlur, sx, inputStyle, initialValue, labelVariant = 'body1' }: PriceFieldProps) => {
    const [value, setValue] = useState<string | undefined>(initialValue ? normalizePrice(initialValue) : initialValue)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isOptimized, setIsOptimized] = useState<boolean>(false)
    const transformText = (inputtedValue: string): string => {
        const previousValue = value || '0.00'
        const newValue = isValidPrice(inputtedValue) ? inputtedValue : previousValue
        setValue(newValue)
        return newValue
    }

    const onBlur = (value: string): string => {
        const newValue = normalizePrice(value)
        setValue(newValue)
        return newValue
    }

    const priceOptimization = async () => {
        setIsLoading(true)
        // choose random number between 1.06 and 1.17
        const randomPrice = Math.random() * (1.17 - 1.06) + 1.06
        const newPrice = Number(value) * randomPrice

        ///choose random wait time between 0.75 and 1.5 seconds
        const randomWaitTime = Math.random() * (1.5 - 0.75) + 0.75
        // wait for random time
        await delayMs(randomWaitTime * 1000)

        setValue(newPrice.toFixed(2))
        setIsLoading(false)
        setIsOptimized(true)
    }

    return (
        <Box>
            <Box sx={{ marginBottom: '16px', display: 'flex', alignItems: 'center', height: '40px' }}>
                <FoxTypography variant='body2' sx={{ fontWeight: 'bold' }}>
                    {label}
                </FoxTypography>
                <FlexGrow />
                {value && value !== '0' && isOptimized === false && (
                    <FoxButton
                        variant='contained'
                        disabled={isLoading}
                        onFoxClick={{ kind: 'button', onClick: async () => await priceOptimization(), preventDoubleClick: true }}
                        text='Optimize price'
                        sx={{ marginRight: '16px', fontWeight: 'bold', height: '40px' }}
                        startIcon={<AutoAwesome />}
                        grey
                    />
                )}
            </Box>
            <FoxTextField
                name={name}
                placeholder='0.00'
                inputStyle={{ ...inputStyle }}
                value={value}
                transformText={transformText}
                onBlur={onBlur}
                disabled={disabled}
                prefix={'$'}
                logOnBlur={logOnBlur}
                sx={{ ...sx }}
            />
        </Box>
    )
}
