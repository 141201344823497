export {}
import React, { useEffect, useState } from 'react'
import {
    getActiveListingCount,
    Logger,
    selectActiveListingCount,
    selectListingsLimit,
    selectSubscription,
    selectSubscriptionTier,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Box, Divider, LinearProgress } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { AccountHeader } from '../../layouts/AccountHeader'
import { SubscriptionCard } from '../../components/account/SubscriptionCard'
import { MustGoOnDeviceToSubscribeModal } from '../../modals/account/marketConnections/MustGoOnDeviceToSubscribe'
import { ListingManagementTitle } from '../../components/account/ListingManagementTitle'
import { CurrentSubscriptionCard } from '../../components/account/CurrentSubscriptionCard'
import { BillingDetailsButton } from '../../components/account/BillingDetailsButton'
import { UserSubscriptionTier } from '@foxtail-dev/datacontracts/dist/lib/schemas/subscriptions/UserSubscription'

export const subscriptionTierOrder: UserSubscriptionTier[] = ['starter', 'basic', 'premium', 'pro', 'enterprise']

const SubscriptionScreen: React.FC = () => {
    const dispatch = useAppDispatch()
    const subscription = useAppSelector(selectSubscription)
    const currentActiveListingCount = useAppSelector(selectActiveListingCount)
    const listingsLimit = useAppSelector(selectListingsLimit)
    const subscriptionTier = useAppSelector(selectSubscriptionTier)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const progress = subscriptionTier === 'enterprise' || listingsLimit === 0 ? 100 : (currentActiveListingCount / listingsLimit) * 100
    const currentTierIndex = subscriptionTier ? (subscriptionTier === 'none' ? -1 : subscriptionTierOrder.indexOf(subscriptionTier)) : -1

    const tiersToShow = subscription?.kind === 'none' ? subscriptionTierOrder : subscriptionTierOrder.slice(currentTierIndex)

    useEffect(() => {
        Logger.I().log({
            level: 'info',
            message: 'User viewed subscription screen',
            payload: {
                kind: 'UserAction',
                entry: {
                    subscription,
                    currentActiveListingCount
                }
            }
        })

        dispatch(getActiveListingCount())

        const activeListingCountsInterval = setInterval(() => {
            dispatch(getActiveListingCount())
        }, 5000)

        return () => {
            clearInterval(activeListingCountsInterval)
        }
    }, [])

    return (
        <Box sx={styles.container}>
            <AccountHeader title='Subscription' />

            <Box sx={styles.containerMain}>
                <CurrentSubscriptionCard />

                <BillingDetailsButton />

                {subscriptionTier !== 'pro' && (
                    <>
                        <FoxTypography sx={styles.textAllPlans} variant='subtitle1'>
                            All plans
                        </FoxTypography>

                        <Box sx={styles.containerSubscriptionCards}>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    gap: 2
                                }}>
                                {tiersToShow.map(tier => (
                                    <SubscriptionCard key={tier} subscriptionKind={tier} setModalOpen={setModalOpen} sx={styles.subscriptionCard} />
                                ))}
                            </Box>
                        </Box>
                    </>
                )}
                <ListingManagementTitle />

                <Divider sx={styles.divider} />

                <LinearProgress sx={styles.linearProgress} value={progress} variant='determinate' />
            </Box>

            {modalOpen && <MustGoOnDeviceToSubscribeModal open={modalOpen} onClose={() => setModalOpen(false)} />}
        </Box>
    )
}

const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '260px'
    },
    containerMain: {
        marginBottom: '32px',
        marginLeft: '32px'
    },
    containerSubscriptionCards: {
        flexDirection: 'row',
        display: 'flex',
        marginBottom: '38px'
    },
    subscriptionCard: {
        marginRight: '20px'
    },
    divider: {
        color: '#F2ECF3',
        marginBottom: '35px'
    },
    linearProgress: {
        height: '13px'
    },
    textAllPlans: {
        marginTop: '47px',
        marginBottom: '24px'
    }
}

export default SubscriptionScreen
