import { UserSubscriptionTier } from '@foxtail-dev/datacontracts/dist/lib/schemas/subscriptions/UserSubscription'
import { Colors } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { CheckIcon } from '../icons/CheckIcon'
import { SubscriptionInfoMap } from '../account/SubscriptionCardMobile'

type InactiveSubscriptionCardProps = {
    onSubscribe: (subscriptionTier: UserSubscriptionTier) => Promise<void>
    subscriptionTier: UserSubscriptionTier
    subscriptionKind: 'none' | 'active' | 'inactive' | 'deactivated' | undefined
}

export const InactiveSubscriptionCard = (props: InactiveSubscriptionCardProps) => {
    const { onSubscribe, subscriptionTier } = props

    return (
        <Box
            sx={{
                height: '545px',
                width: '342px',
                borderWidth: '1px',
                borderColor: Colors.light.divider,
                borderStyle: 'solid',
                borderRadius: '30px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginRight: '25px',
                boxShadow: '0px 32px 84px rgba(0, 0, 0, 0.09)',
                maxWidth: '260px'
            }}>
            <FoxTypography sx={{ marginTop: '37px', marginBottom: '5px', lineHeight: '34px', fontSize: '28px', fontWeight: 700 }}>
                {SubscriptionInfoMap[subscriptionTier].displayName}
            </FoxTypography>
            <FoxTypography sx={{ marginBottom: '14px', lineHeight: '20px', fontSize: '14px' }}>
                {SubscriptionInfoMap[subscriptionTier].description}
            </FoxTypography>
            <Box sx={{ height: '60px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '28.6px' }}>
                {subscriptionTier === 'enterprise' ? (
                    <FoxTypography sx={{ fontSize: '24px', fontWeight: 700, marginRight: '11px' }}>Custom pricing</FoxTypography>
                ) : (
                    <>
                        <FoxTypography sx={{ fontSize: '20px', fontWeight: 700, marginRight: '13.7px' }}>$</FoxTypography>
                        <FoxTypography sx={{ fontSize: '40px', fontWeight: 700, marginRight: '11px' }}>
                            {SubscriptionInfoMap[subscriptionTier].price}
                        </FoxTypography>
                        <FoxTypography light sx={{ fontSize: '20px', fontWeight: 400 }}>
                            /month
                        </FoxTypography>
                    </>
                )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '25px' }}>
                <FoxTypography sx={{ lineHeight: '20px', fontSize: '14px', marginRight: '4px' }}>Up to</FoxTypography>
                <FoxTypography sx={{ lineHeight: '20px', fontSize: '14px', fontWeight: 700 }}>
                    {subscriptionTier === 'enterprise' ? '1000+ listings' : SubscriptionInfoMap[subscriptionTier].listingLimit} listings
                </FoxTypography>
            </Box>
            <FoxButton
                text={subscriptionTier === 'enterprise' ? 'Contact us' : `Get ${SubscriptionInfoMap[subscriptionTier].displayName}`}
                primary
                sx={{ color: 'white', width: '90%', justifyContent: 'center', borderRadius: '26.5px', marginBottom: '29px' }}
                onFoxClick={{ kind: 'button', onClick: async () => await onSubscribe(subscriptionTier), preventDoubleClick: true }}
            />
            <Box sx={{ width: '227px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                <FoxTypography sx={{ fontSize: '14px', lineHeight: '20px', marginBottom: '23px', fontWeight: 700 }}>Plus</FoxTypography>
                {props.subscriptionKind === 'none' && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                        <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                        <FoxTypography sx={{ fontSize: '14px', lineHeight: '20px', marginLeft: '10.5px' }}>14 day free trial</FoxTypography>
                    </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                    <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                    <FoxTypography sx={{ fontSize: '14px', lineHeight: '20px', marginLeft: '10.5px' }}>AI features</FoxTypography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                    <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                    <FoxTypography sx={{ fontSize: '14px', lineHeight: '20px', marginLeft: '10.5px' }}>Import listings</FoxTypography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                    <FoxTypography sx={{ fontSize: '14px', lineHeight: '20px', marginLeft: '10.5px' }}>Bulk crosslisting</FoxTypography>
                </Box>
            </Box>
        </Box>
    )
}
