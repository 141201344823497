import { Box, Divider } from '@mui/material'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import {
    EngagementBoosterSpecificFieldsMap,
    EngagementBoosterToDomainMap,
    EngagementBoosterToHelpArticleMap,
    EngagementBoosterToTitleMap
} from '../../containers/engagementBoosters/EngagementBoosterSpecificFieldsMap'
import { EngagementBoostersData } from '../../screens/engagementBoosters/EngagementBoostersScreen'
import { UserEngagementBoosterKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/users/engagementBoosters/engagementBoosters.exports'
import {
    createInitialEngagementBoosterConfig,
    selectEngagementBoostersConfig,
    selectLoggedInDomains,
    selectSubscription,
    setEngagementBoosters,
    useAppDispatch,
    useAppSelector,
    UserEngagementBoostersPartialConfigData,
    validateEngagementBoosterConfig
} from '@foxtail-dev/user-clients'
import { Logger } from '../../lib/clients/Logger'
import { FlexGrow } from '../../components/common/FlexGrow'
import { DomainGuardModal } from '../account/DomainGuardModal'
import { useState } from 'react'
import { UpgradeGuardModal } from '../account/UpgradeGuardModal'
import { HelpIcon } from '../../components/icons/HelpIcon'
import * as Intercom from '@intercom/messenger-js-sdk'
import { FoxTypography } from '../../components/common/FoxTypography'
import { shouldDisplayErrorMessage } from '../../utils/shouldDisplayErrorMessage'

type EditEngagementBoosterModalProps = {
    closeModal: () => void
    openBoosterKind: UserEngagementBoosterKind | null
}

export const EditEngagementBoosterModal = ({ closeModal, openBoosterKind }: EditEngagementBoosterModalProps) => {
    const title = openBoosterKind ? EngagementBoosterToTitleMap[openBoosterKind] : ''

    return (
        <FoxModalDialog open={!!openBoosterKind} title={title} leftButtonKind={'back'} onClose={closeModal}>
            {openBoosterKind && <EditEngagementBoosterForm closeModal={closeModal} boosterKind={openBoosterKind} />}
        </FoxModalDialog>
    )
}

type EditEngagementBoosterFormProps = {
    closeModal: () => void
    boosterKind: UserEngagementBoosterKind
}

const EditEngagementBoosterForm = ({ closeModal, boosterKind }: EditEngagementBoosterFormProps) => {
    const dispatch = useAppDispatch()
    const engagementBoosterConfig = useAppSelector(selectEngagementBoostersConfig)
    const loggedInDomains = useAppSelector(selectLoggedInDomains)
    const subscription = useAppSelector(selectSubscription)

    const [showDomainGuardModal, setShowDomainGuardModal] = useState(false)
    const [showUpgradeGuardModal, setShowUpgradeGuardModal] = useState(false)

    // Existing values in the config are preserved. If there aren't any values for this specific config, we create an initial one
    const specificConfig = engagementBoosterConfig?.[boosterKind] ?? createInitialEngagementBoosterConfig(boosterKind)

    const initialValues: UserEngagementBoostersPartialConfigData = {
        ...engagementBoosterConfig,
        [boosterKind]: specificConfig
    }

    const onHelp = (articleId: string) => {
        Logger.I().log({
            level: 'info',
            message: 'Clicking info button',
            payload: {
                kind: 'UserAction',
                entry: {}
            }
        })
        Intercom.showArticle(articleId)
    }

    const onSubmit = async (values: EngagementBoostersData, helpers: FormikHelpers<EngagementBoostersData>) => {
        Logger.I().log({
            level: 'info',
            message: 'Submitting engagement boosters',
            payload: {
                kind: 'UserAction',
                entry: {
                    values: values
                }
            }
        })

        try {
            const validationFailures = validateEngagementBoosterConfig(values, boosterKind)

            if (validationFailures.length > 0) {
                Logger.I().log({
                    level: 'error',
                    message: `Validation errors submitting engagement boosters for kind: ${boosterKind}`,
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            values,
                            validationFailures,
                            boosterKind
                        }
                    }
                })

                validationFailures.forEach(failure => {
                    helpers.setFieldError(failure.field, failure.message)
                    helpers.setFieldTouched(failure.field, true)
                })
                return
            }

            const domain = EngagementBoosterToDomainMap[boosterKind]
            // Specifically showing the upgrade guard before the domain guard
            // TODO: Replace with selector
            // TODO subscription update verify this
            // const hasPaidSubscription = subscription?.kind === 'active' && subscription.details.tier !== 'freetrial'
            const hasPaidSubscription = subscription?.kind === 'active'

            if (!hasPaidSubscription) {
                Logger.I().log({
                    level: 'info',
                    message: 'User is not on a paid subscription',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            values: values,
                            domain: domain,
                            subscription: subscription
                        }
                    }
                })
                setShowUpgradeGuardModal(true)
                return
            }

            const isLoggedIntoDomain = loggedInDomains.includes(domain)

            if (!isLoggedIntoDomain) {
                Logger.I().log({
                    level: 'info',
                    message: 'User is not logged into domain',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            values: values,
                            domain: domain
                        }
                    }
                })

                setShowDomainGuardModal(true)
                return
            }

            const response = await dispatch(setEngagementBoosters(values)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'Successfully submitted engagement boosters',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        values: values,
                        response
                    }
                }
            })

            closeModal()
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error submitting engagement boosters',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            values: values
                        }
                    }
                },
                error
            )
        }
    }

    const onDomainGuardModalClose = () => {
        setShowDomainGuardModal(false)
    }

    const onUpgradeGuardModalClose = () => {
        setShowUpgradeGuardModal(false)
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={false} validateOnBlur={false} validateOnChange={false}>
            {({ isSubmitting, handleSubmit, errors, touched, values }: FormikProps<EngagementBoostersData>) => {
                const Field = EngagementBoosterSpecificFieldsMap[boosterKind]
                const domain = EngagementBoosterToDomainMap[boosterKind]
                const helpArticleId = EngagementBoosterToHelpArticleMap[boosterKind]

                const domainGuardText = `To share your Poshmark closet automatically, you need to connect your Poshmark account.`

                return (
                    <>
                        <Field />
                        <Divider />

                        <DomainGuardModal domain={domain} open={showDomainGuardModal} text={domainGuardText} onClose={onDomainGuardModalClose} />
                        <UpgradeGuardModal open={showUpgradeGuardModal} onClose={onUpgradeGuardModalClose} reason='engagementBooster' markets={[domain]} />

                        {shouldDisplayErrorMessage(touched, errors) && (
                            <Box sx={styles.containerValidation}>
                                <FoxTypography variant='body1' danger sx={styles.textError}>
                                    Fix form errors to save changes
                                </FoxTypography>
                            </Box>
                        )}

                        <Box sx={{ display: 'flex', marginTop: '16px' }}>
                            <FlexGrow />

                            {helpArticleId && (
                                <FoxButton
                                    variant='contained'
                                    grey
                                    size='large'
                                    text='Info'
                                    startIcon={<HelpIcon />}
                                    disabled={isSubmitting}
                                    onFoxClick={{
                                        kind: 'button',
                                        onClick: async () => {
                                            onHelp(helpArticleId)
                                        }
                                    }}
                                    sx={{ display: 'flex', justifyContent: 'center', marginRight: '24px', width: '150px' }}
                                />
                            )}
                            <FoxButton
                                variant='contained'
                                grey
                                size='large'
                                text='Discard changes'
                                disabled={isSubmitting}
                                onFoxClick={{
                                    kind: 'button',
                                    onClick: async () => {
                                        closeModal()
                                    }
                                }}
                                sx={{ display: 'flex', justifyContent: 'center', marginRight: '24px' }}
                            />
                            <FoxButton
                                variant='contained'
                                primary
                                size='large'
                                text='Save changes'
                                type='submit'
                                loading={isSubmitting}
                                onFoxClick={{
                                    kind: 'button',
                                    onClick: async () => {
                                        handleSubmit()
                                    },
                                    preventDoubleClick: true
                                }}
                                sx={{ display: 'flex', justifyContent: 'center' }}
                            />
                        </Box>
                    </>
                )
            }}
        </Formik>
    )
}

const styles = {
    containerValidation: {
        marginLeft: '4px',
        marginTop: '4px'
    },
    textError: {
        fontSize: '12px',
        lineHeight: '16px'
    }
}
