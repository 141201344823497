import { useAppSelector, selectSubscriptionTier, selectSubscription, Colors, UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { Divider } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'

export const BillingDetailsButton = () => {
    // TODO verify the subscriptionTier "none" case is covered
    // if stripe subscription kind and not freetrial allow access to billing portal
    const currentSubscriptionTier = useAppSelector(selectSubscriptionTier)
    const subscription = useAppSelector(selectSubscription)

    const onViewBillingPortal = async () => {
        const context = await UserRuntimeContextProvider.getContext()
        const { sessionUrl } = await context.userApiClient.stripe.createBillingPortalSession({
            returnUrl: `${window.location.origin}/app/account/subscription`
        })
        window.location.href = sessionUrl
    }

    return (
        subscription?.kind === 'active' &&
        subscription.details.kind === 'stripe' && (
            <>
                <FoxTypography sx={{ marginTop: '32px', marginBottom: '11px' }} variant='subtitle1'>
                    Billing
                </FoxTypography>
                <Divider />
                <FoxTypography
                    onClick={onViewBillingPortal}
                    sx={{ marginTop: '22px', marginBottom: '8px', color: Colors.light.primary, cursor: 'pointer' }}
                    variant='subtitle1'>
                    View your billing dashboard
                </FoxTypography>
                <FoxTypography sx={{ lineHeight: '16px', cursor: 'default' }} variant='body1' light>
                    Update your payment method, view your invoices, or manage your subscription
                </FoxTypography>
            </>
        )
    )
}
