import React, { useEffect } from 'react'
import Box from '@mui/material/Box/Box'
import { FoxButton } from '../../components/common/FoxButton'
import { Form, Formik, FormikHelpers } from 'formik'
import { FoxTextField } from '../../components/formik/FoxTextField'
import { z } from '@foxtail-dev/datacontracts'
import { isEmailUnique, Logger, selectUserInfo, updateUserInfo, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { useNavigate } from 'react-router-dom'
import { generateToast } from '../../lib/clients/ToastClient'
import { OnboardingHeader } from '../../layouts/OnboardingHeader'
import { useAuth0 } from '@auth0/auth0-react'
import { OnboardingSignOutButton } from '../../components/onboarding/OnboardingSignOutButton'
import { useMediaQuery, useTheme } from '@mui/material'

export const AccountBasicInfoSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email()
})

export type AccountBasicInfoSchema = z.infer<typeof AccountBasicInfoSchema>

export const initialValues: AccountBasicInfoSchema = {
    firstName: '',
    lastName: '',
    email: ''
}

const BasicInfoScreen = (): JSX.Element => {
    const { user } = useAuth0()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [shouldNavigate, setShouldNavigate] = React.useState(false)

    const onSubmit = async (values: AccountBasicInfoSchema, actions: FormikHelpers<AccountBasicInfoSchema>) => {
        try {
            const isEmailUniqueResponse = await dispatch(isEmailUnique(values.email)).unwrap()
            if (!isEmailUniqueResponse.isUnique) {
                actions.setFieldError('email', 'Email already in use by another account')
                return
            }

            values.email = values.email.toLowerCase()

            await dispatch(updateUserInfo(values)).unwrap()
            Logger.I().log({
                level: 'info',
                message: 'User updated basic info',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        values: values
                    }
                }
            })
            setShouldNavigate(true)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Unable to update user info',
                    payload: {
                        kind: 'UpdateUserInfoError',
                        entry: {
                            values: values
                        }
                    }
                },
                error
            )

            generateToast({ kind: 'error', message: 'Unable to update user info', subText: 'Please try again later' })
        }
    }

    const userInfo = useAppSelector(selectUserInfo)

    useEffect(() => {
        if (shouldNavigate) {
            navigate('/subscribe-onboarding')
        }
    }, [shouldNavigate])

    const initialValues: AccountBasicInfoSchema = {
        firstName: '',
        lastName: '',
        email: user?.email || userInfo?.email || ''
    }

    return (
        <Box sx={isMobile ? mobileStyles.container : styles.container}>
            <OnboardingHeader title="Let's get some basic info" subtitle='' />

            <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur={false}>
                {formikProps => {
                    const { isSubmitting, values } = formikProps
                    return (
                        <Form>
                            <Box>
                                <Box sx={[isMobile ? mobileStyles.containerTextFieldBase : styles.containerTextFieldBase, { marginBottom: '15px' }]}>
                                    <FoxTextField
                                        inputStyle={isMobile ? mobileStyles.textFieldInput : styles.textFieldInput}
                                        name='firstName'
                                        label='First name'
                                        value={values.firstName}
                                        placeholder='First name'
                                    />
                                </Box>
                                <Box sx={[isMobile ? mobileStyles.containerTextFieldBase : styles.containerTextFieldBase, { marginBottom: '26px' }]}>
                                    <FoxTextField
                                        inputStyle={isMobile ? mobileStyles.textFieldInput : styles.textFieldInput}
                                        name='lastName'
                                        label='Last name'
                                        value={values.lastName}
                                        placeholder='Last name'
                                    />
                                </Box>
                                <Box sx={isMobile ? mobileStyles.containerTextFieldBase : styles.containerTextFieldBase}>
                                    <FoxTextField
                                        value={values.email}
                                        inputStyle={isMobile ? mobileStyles.textFieldInput : styles.textFieldInput}
                                        name='email'
                                        label='Email'
                                        placeholder='Email'
                                    />
                                </Box>
                            </Box>
                            <Box style={isMobile ? mobileStyles.containerButton : styles.containerButton}>
                                <FoxButton
                                    sx={isMobile ? mobileStyles.button : styles.button}
                                    primary
                                    text='Next'
                                    variant='contained'
                                    onFoxClick={{ kind: 'button', onClick: async () => {} }}
                                    type='submit'
                                    loading={isSubmitting}
                                />
                            </Box>

                            <OnboardingSignOutButton />
                            <Box sx={{ height: '40px' }} />
                        </Form>
                    )
                }}
            </Formik>
        </Box>
    )
}

const styles = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    containerButton: {
        marginTop: '48px'
    },
    button: {
        justifyContent: 'center',
        marginBottom: '57px',
        width: '474px',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px'
    },
    containerTextFieldBase: {
        width: '474px',
        height: '73px',
        display: 'flex'
    },
    textFieldInput: {
        width: '471px'
    }
}

const mobileStyles = {
    container: {
        justifyContent: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '13px',
        marginRight: '14px'
    },
    containerButton: {
        marginTop: '60px'
    },
    button: {
        justifyContent: 'center',
        marginBottom: '24px',
        width: '100%',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px'
    },
    containerTextFieldBase: {
        width: '100%',
        height: 'auto',
        marginBottom: '15px'
    },
    textFieldInput: {
        width: '100%'
    }
}

export default BasicInfoScreen
