import { Box, Divider } from '@mui/material'
import * as Intercom from '@intercom/messenger-js-sdk'
import { BellIcon } from '../components/icons/BellIcon'
import { SettingsIcon } from '../components/icons/SettingsIcon'
import { HelpIcon } from '../components/icons/HelpIcon'
import { FoxTypography } from '../components/common/FoxTypography'
import {
    selectActiveListingCount,
    selectAreAnyNotificationsUnread,
    selectImportListingCancelInfo,
    selectImportListingSessionStatus,
    selectSubscriptionTier,
    selectUserInfo,
    SubscriptionTierInfoMap,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { getInitials } from '../utils/getInitials'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Colors } from '../theme/Colors'
import { BellDotIcon } from '../components/icons/BellDotIcon'
import { FoxButtonBase } from '../components/common/FoxButtonBase'
import { createLogoutUrl } from '../utils/createLogoutUrl'
import { FoxtailWebConfig } from '../lib/config/FoxtailWebConfig'
import { FlexGrow } from '../components/common/FlexGrow'
import { ImportReadyForReviewButton } from '../containers/account/ImportReadyForRevewButton'
import { UpgradeProgressHeader } from '../containers/account/UpgradeProgressHeader'
import { AvatarMenu } from '../containers/account/AvatarMenu'
import { UpgradeButtonHeader } from '../containers/account/UpgradeButtonHeader'

export const AppHeader = () => {
    const { logout } = useAuth0()
    const userInfo = useAppSelector(selectUserInfo)
    const currentActiveListingCount = useAppSelector(selectActiveListingCount)
    const subscriptionTier = useAppSelector(selectSubscriptionTier)
    const [isIntercomOpen, setIsIntercomOpen] = useState(false)
    const doesUserHaveUnreadNotifications = useAppSelector(selectAreAnyNotificationsUnread)
    const importListingStatus = useAppSelector(selectImportListingSessionStatus)
    const isImportCanceling = useAppSelector(selectImportListingCancelInfo)

    useEffect(() => {
        const handleShow = () => setIsIntercomOpen(true)
        const handleHide = () => setIsIntercomOpen(false)

        if (window.Intercom) {
            window.Intercom('onShow', handleShow)
            window.Intercom('onHide', handleHide)
        }

        return () => {
            if (window.Intercom) {
                window.Intercom('offShow', handleShow)
                window.Intercom('offHide', handleHide)
            }
        }
    }, [])

    const onHelp = async () => {
        if (isIntercomOpen === false) {
            Intercom.show()
        } else {
            Intercom.hide()
        }
    }

    const onLogout = async (e: React.MouseEvent) => {
        e.stopPropagation()
        await logout({
            logoutParams: {
                returnTo: createLogoutUrl(window.location.origin, 'logout')
            },
            clientId: FoxtailWebConfig.config.auth0.clientId
        })
    }

    const initials = getInitials({ firstName: userInfo?.firstName, lastName: userInfo?.lastName })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    // TODO: Notification button should be its own icon
    //TODO update subscription tier map to include none
    const subscriptionTierNonNone = subscriptionTier === 'none' ? 'starter' : subscriptionTier

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerRow}>
                {importListingStatus === 'awaiting-user-input' && isImportCanceling.isCancelling === false && <ImportReadyForReviewButton />}
                <FlexGrow />
                {subscriptionTierNonNone !== 'pro' && (
                    <>
                        <UpgradeProgressHeader
                            currentActiveListingCount={currentActiveListingCount}
                            listingLimit={SubscriptionTierInfoMap[subscriptionTierNonNone].listingLimit}
                        />
                        <UpgradeButtonHeader />
                    </>
                )}
                <FoxButtonBase sx={styles.containerHeaderIcon} onFoxClick={{ kind: 'internal', to: '/app/notifications' }}>
                    {doesUserHaveUnreadNotifications ? <BellDotIcon /> : <BellIcon />}
                </FoxButtonBase>
                <FoxButtonBase sx={styles.containerHeaderIcon} onFoxClick={{ kind: 'button', onClick: onHelp }}>
                    <HelpIcon />
                </FoxButtonBase>
                <FoxButtonBase sx={styles.buttonSettings} onFoxClick={{ kind: 'button', onClick: handleClick }}>
                    <Box sx={styles.containerSettings}>{initials ? <FoxTypography sx={styles.textInitials}>{initials}</FoxTypography> : <SettingsIcon />}</Box>
                </FoxButtonBase>
                <AvatarMenu anchorEl={anchorEl} handleClose={handleClose} onLogout={onLogout} open={!!anchorEl} />
            </Box>
            <Divider />
        </Box>
    )
}

const styles = {
    container: {
        height: '64px',
        position: 'fixed',
        width: '100%',
        paddingLeft: '240px',
        opacity: 1,
        zIndex: 1000
    },
    containerHeaderIcon: {
        marginRight: '23px',
        display: 'flex',
        cursor: 'pointer'
    },
    containerRow: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '61px',
        height: '63px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: Colors.light.background
    },
    containerSettings: {
        height: '32px',
        width: '32px',
        backgroundColor: Colors.light.tertiary,
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textInitials: {
        color: Colors.light.background,
        fontSize: '14px',
        fontWeight: 500,
        cursor: 'pointer'
    },
    buttonSettings: {
        margin: 0,
        padding: 0,
        width: 'auto',
        minWidth: 'unset'
    }
}
