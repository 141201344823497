import { Box, SxProps, Theme } from '@mui/material'
import { Colors } from '../../theme/Colors'
import { FoxTypography } from '../common/FoxTypography'
import { UserSubscriptionTier } from '@foxtail-dev/datacontracts/dist/lib/schemas/subscriptions/UserSubscription'
import { SubscriptionCardFeatureRowMobile } from '../../containers/subscription/SubscriptionCardFeatureRowMobile'
import { CheckBoxCheckedIcon } from '../icons/CheckBoxChecked'
import { CheckBoxUncheckedIcon } from '../icons/CheckBoxUnchecked'
import { FoxButtonBase } from '../common/FoxButtonBase'

// TODO use the one in user-clients and move descriptiona and display name there
export const SubscriptionInfoMap: Record<
    UserSubscriptionTier,
    {
        displayName: string
        description: string
        price: number
        listingLimit: number
    }
> = {
    starter: {
        displayName: 'Starter',
        description: 'Try it out',
        price: 4.99,
        listingLimit: 10
    },
    basic: {
        displayName: 'Basic',
        description: 'For the casual seller',
        price: 19.99,
        listingLimit: 50
    },
    premium: {
        displayName: 'Premium',
        description: 'For the serious seller',
        price: 39.99,
        listingLimit: 250
    },
    pro: {
        displayName: 'Pro',
        description: 'For the power seller',
        price: 79.99,
        listingLimit: 1000
    },
    'grandfathered-pro': {
        displayName: 'Pro',
        description: 'For the power seller',
        price: 79.99,
        listingLimit: 10000000
    },
    enterprise: {
        displayName: 'Enterprise',
        description: 'For the at scale power seller',
        price: 79.99,
        listingLimit: 10000000
    }
}

type SubscriptionCardMobileProps = {
    currentlySelectedTier: UserSubscriptionTier
    setCurrentlySelectedTier: (tier: UserSubscriptionTier) => void
    subscriptionKind: UserSubscriptionTier

    sx?: SxProps<Theme>
}
export const SubscriptionCardMobile = (props: SubscriptionCardMobileProps) => {
    const { subscriptionKind, currentlySelectedTier } = props
    const isCurrentSubscription = subscriptionKind === currentlySelectedTier

    return (
        <FoxButtonBase
            onFoxClick={{
                onClick: async () => {
                    props.setCurrentlySelectedTier(subscriptionKind)
                },
                kind: 'button'
            }}
            sx={{
                height: '224px',
                borderRadius: '10px',
                borderColor: isCurrentSubscription ? Colors.light.tertiary : Colors.light.divider,
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                marginBottom: '12px',
                flexDirection: 'row',
                display: 'flex',
                ...props.sx
            }}>
            <Box sx={{ flex: 4 }}>
                <FoxTypography sx={{ marginTop: '9px', marginLeft: '17px', marginBottom: '8px' }} variant='body2'>
                    {SubscriptionInfoMap[subscriptionKind].displayName}
                </FoxTypography>

                <SubscriptionCardFeatureRowMobile
                    feature={
                        subscriptionKind === 'enterprise'
                            ? '1000+ listings per month'
                            : `Up to  ${SubscriptionInfoMap[subscriptionKind].listingLimit} listings per month`
                    }
                />
                <SubscriptionCardFeatureRowMobile feature='AI content generation' />
                <SubscriptionCardFeatureRowMobile feature='Importing' />
                <SubscriptionCardFeatureRowMobile feature='Bulk crosslisting' />
                <SubscriptionCardFeatureRowMobile feature='Unlimited crosslisting' />
                <Box
                    sx={{
                        flexDirection: 'row',
                        display: 'flex',
                        marginLeft: '17px',
                        marginTop: '8px',
                        height: '32px',
                        alignItems: 'flex-end',
                        marginBottom: '8px'
                    }}>
                    {subscriptionKind === 'enterprise' ? (
                        <FoxTypography sx={{ fontSize: '20px', fontWeight: 700 }}>Custom pricing</FoxTypography>
                    ) : (
                        <>
                            <Box sx={{ marginRight: '6px' }}>
                                <FoxTypography sx={{ fontSize: '20px', fontWeight: 700 }}>${SubscriptionInfoMap[subscriptionKind].price}</FoxTypography>
                            </Box>
                            <Box>
                                <FoxTypography light sx={{ fontSize: '12px' }}>
                                    / month
                                </FoxTypography>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', marginRight: '18px' }}>
                {isCurrentSubscription ? <CheckBoxCheckedIcon height={18} width={18} /> : <CheckBoxUncheckedIcon height={18} width={18} />}
            </Box>
        </FoxButtonBase>
    )
}
