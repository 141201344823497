import { isValidPrice, normalizePrice } from '@foxtail-dev/user-clients'
import { FoxTextField } from './FoxTextField'
import { useMemo, useState } from 'react'
import { Box, BoxProps, TextFieldProps, TypographyVariant } from '@mui/material'
import { AutoAwesome } from '@mui/icons-material'
import { FlexGrow } from '../common/FlexGrow'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { delayMs } from '@foxtail-dev/datacontracts'

type QuantityFieldProps = {
    initialValue?: string
}

export const QuantityField = ({ initialValue }: QuantityFieldProps) => {
    return (
        <Box>
            <Box sx={{ marginBottom: '16px', display: 'flex', alignItems: 'center', height: '40px' }}>
                <FoxTypography variant='body2' sx={{ fontWeight: 'bold' }}>
                    Quantity
                </FoxTypography>
                <FlexGrow />
            </Box>
            <FoxTextField name='commonDetails.quantity' value={initialValue?.toString()} sx={{ flexGrow: 1 }} inputStyle={{ width: '100%' }} placeholder='0' />
        </Box>
    )
}
