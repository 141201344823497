import { Box } from '@mui/material'
import { FoxImage } from '../components/common/FoxImage'
import { CommonImages } from '../theme/CommonImages'
import { FoxTypography } from '../components/common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Colors, selectSubscription, useAppSelector, UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { FoxButton } from '../components/common/FoxButton'
import { FoxtailLogoAndNameIcon } from '../components/icons/FoxtailLogoAndName'
import { useAuth0 } from '@auth0/auth0-react'
import { generateToast } from '../lib/clients/ToastClient'
import { useEffect, useState } from 'react'
import { UserSubscriptionTier } from '@foxtail-dev/datacontracts/dist/lib/schemas/subscriptions/UserSubscription'
import { useNavigate } from 'react-router-dom'
import { createLogoutUrl } from '../utils/createLogoutUrl'
import { FoxtailWebConfig } from '../lib/config/FoxtailWebConfig'
import RewardfulClient from '../lib/clients/RewardfulClient'
import { subscriptionTierOrder } from './account/SubscriptionScreen'
import { InactiveSubscriptionCard } from '../components/onboarding/InactiveSubscriptionCard'
import * as Intercom from '@intercom/messenger-js-sdk'

export const InactiveSubscriptionScreen = () => {
    const { logout } = useAuth0()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const subscription = useAppSelector(selectSubscription)
    const [isIntercomOpen, setIsIntercomOpen] = useState(false)

    useEffect(() => {
        if (subscription && subscription.kind === 'active') {
            navigate('/app/home')
        }
    }, [subscription])

    useEffect(() => {
        const handleShow = () => setIsIntercomOpen(true)
        const handleHide = () => setIsIntercomOpen(false)

        if (window.Intercom) {
            window.Intercom('onShow', handleShow)
            window.Intercom('onHide', handleHide)
        }

        return () => {
            if (window.Intercom) {
                window.Intercom('offShow', handleShow)
                window.Intercom('offHide', handleHide)
            }
        }
    }, [])

    const onSubscribe = async (subscriptionKind: UserSubscriptionTier) => {
        if (isLoading) return
        setIsLoading(true)

        if (subscriptionKind === 'enterprise') {
            if (!isIntercomOpen) {
                Intercom.showNewMessage("I'd like to subscribe to an enterprise plan")
            } else {
                Intercom.hide()
            }
            return
        }

        try {
            const context = await UserRuntimeContextProvider.getContext()
            const referralId = await RewardfulClient.getReferralId()
            const { sessionUrl } = await context.userApiClient.stripe.createCheckoutSession({
                cancelUrl: `${window.location.origin}/app/account/subscription`,
                successUrl: `${window.location.origin}/subscription-success?subscriptionTier=${subscriptionKind}&isUpgrade=true`,
                subscriptionTier: subscriptionKind,
                referralId
            })
            window.open(sessionUrl)
            setIsLoading(false)
        } catch (error) {
            generateToast({ kind: 'error', message: 'Subscription error. Please contact support' })
            setIsLoading(false)
        }
    }

    return (
        <Box
            sx={{
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                px: 2,
                py: 4
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ mt: 4, mb: 6 }}>
                    <FoxtailLogoAndNameIcon height={26} width={120} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mb: 2 }}>
                    <FoxTypography sx={{ fontSize: { xs: '28px', sm: '35px' }, fontWeight: 500, mb: { xs: '10px', sm: '0' }, mr: { sm: '10px' } }}>
                        The Future of
                    </FoxTypography>
                    <FoxTypography sx={{ color: Colors.light.primary, fontSize: { xs: '28px', sm: '35px' }, fontWeight: 500 }}>Reselling</FoxTypography>
                </Box>
                <FoxTypography sx={{ fontSize: { xs: '16px', sm: '20px' }, lineHeight: '24px', fontWeight: 400, display: 'inline' }}>
                    Subscribe to use Foxtail
                    {subscription?.kind === 'none' && (
                        <FoxTypography sx={{ fontWeight: 700, fontSize: { xs: '16px', sm: '20px' }, lineHeight: '24px', display: 'inline' }}>
                            &nbsp;with a 14 day free trial
                        </FoxTypography>
                    )}
                </FoxTypography>

                <Box
                    sx={{
                        mt: { xs: 4, sm: '51px' },
                        mb: { xs: 6, sm: '96px' },
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        gap: 2
                    }}>
                    {subscriptionTierOrder.map(subscriptionTier => (
                        <InactiveSubscriptionCard
                            key={subscriptionTier}
                            subscriptionTier={subscriptionTier}
                            onSubscribe={onSubscribe}
                            subscriptionKind={subscription?.kind}
                        />
                    ))}
                </Box>

                <FoxTypography light sx={{ fontSize: '15px' }}>
                    Reach more buyers with easy cross-listing
                </FoxTypography>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 6, gap: 2 }}>
                    {Object.values(DomainCommonName.Values).map(domainName => (
                        <Box key={'DomainImg-' + domainName}>
                            <Box sx={{ height: '68px', width: '68px', borderRadius: '10px', position: 'relative' }}>
                                <FoxImage
                                    src={CommonImages.markets[DomainCommonName.parse(domainName)]}
                                    alt={domainName}
                                    style={{ borderRadius: '10px', height: '100%', width: '100%' }}
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ height: '60px' }} />
                <FoxButton
                    onFoxClick={{
                        kind: 'button',
                        onClick: async () => {
                            await logout({
                                logoutParams: {
                                    returnTo: createLogoutUrl(window.location.origin, 'logout')
                                },
                                clientId: FoxtailWebConfig.config.auth0.clientId
                            })
                        },
                        preventDoubleClick: true
                    }}
                    grey
                    text='Log out'
                    sx={{ width: '269px', justifyContent: 'center', borderRadius: '26.5px', mb: 4 }}
                />
            </Box>
        </Box>
    )
}
