import { Outlet, useNavigate } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'
import useNavigationLogger from '../router/UseNavigationLogger'
import { useEffect } from 'react'
import {
    useAppSelector,
    selectAppLoaded,
    selectHasActiveSubscription,
    selectIsUserVerified,
    selectHasBasicInfo,
    selectHasSeenConfetti
} from '@foxtail-dev/user-clients'

const UnauthenticatedLayout: React.FC = () => {
    useNavigationLogger()
    const navigate = useNavigate()

    const appLoaded = useAppSelector(selectAppLoaded)
    const isMobile = useMediaQuery('(max-width:600px)')
    const hasActiveSubscription = useAppSelector(selectHasActiveSubscription)
    const isUserVerified = useAppSelector(selectIsUserVerified)
    const hasBasicInfo = useAppSelector(selectHasBasicInfo)
    const hasSeenConfetti = useAppSelector(selectHasSeenConfetti)
    useEffect(() => {
        if (appLoaded) {
            if (isUserVerified === false) {
                navigate('/enter-phone-number')
            } else if (!hasBasicInfo) {
                navigate('/basic-info')
            } else if (hasSeenConfetti === false) {
                navigate('/sign-up-complete')
            } else if (isMobile) {
                navigate('/mobile')
            } else if (!hasActiveSubscription) {
                navigate('/inactive-subscription')
            }
        }
    }, [appLoaded, isMobile])

    return (
        <Box className='Layout'>
            <Outlet />
        </Box>
    )
}

export default UnauthenticatedLayout
